import React from 'react'

const FilterButton = ({text, onClick, type, filter}) => {
  return (
    <button className="filter-button" onClick={onClick}>
      {text}
      <div className={`${type === filter ? "filter-button--active" : ""}`}/>

    </button>
  )
}

export default FilterButton
