import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {functions} from '../helper/firebase'

const ContactForm = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const [ t ] = useTranslation('translation');


  const onNameChange = (e) => {
    setName(e.target.value)
  }
  const onEmailChange = (e) => {
    setEmail(e.target.value)
  }
  const onMessageChange = (e) => {
    setMessage(e.target.value)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setError('')
    if(!name) {
      setError(t('contact_error_name'))
    } else if (!email) {
      setError(t('contact_error_email'))
    } else if (!message) {
      setError(t('contact_error_message'))
    } else {
      //
      //submit
      const sendEmail = functions.httpsCallable('sendEmail')
      sendEmail({name, email, message})
      .then((response) => {
        console.log(response)
        setError(t('message_thanks'))
      })
      .catch(() => {
        setError('This currently does not work :( Please send an email to kevinhaustein16@googlemail.com, while I try to get this form running')
      })
    }

  }

  return (
    <form className="contact-form" onSubmit={onSubmit}>
      {error && <p>{error}</p> }
      <input
        className="contact-form__input"
        type="text"
        placeholder={t('your_name')}
        autoFocus
        value={name}
        onChange={onNameChange}/>
      <input
        className="contact-form__input"
        type="email"
        placeholder={t('your_email')}
        autoFocus
        value={email}
        onChange={onEmailChange}/>
      <textarea
        className="contact-form__textarea"
        type="text"
        placeholder={t('your_message')}
        autoFocus
        value={message}
        onChange={onMessageChange}/>
      <button className="contact-form__submit">{t('submit')}</button>
    </form>
  )
}

export default ContactForm
