import { createStore, combineReducers, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'

import filtersReducer from './reducers/filtersReducer'
import projectsReducer from './reducers/projectsReducer'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default () => {
  const store = createStore(
    combineReducers({
      filters: filtersReducer,
      projects: projectsReducer
    }),
    composeEnhancers(applyMiddleware(thunk))
  )

  return store
}
