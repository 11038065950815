import {db, storage} from '../../helper/firebase'

export const loadProjectsSuccess = (projects) => ({
  type: 'LOAD_PROJECTS',
  projects
})

export const loadProjects = (projectsData = {}) => {
  return (dispatch) => {
    return db.collection("fl_content").where("_fl_meta_.schema", "==",  "projects")
    .get().then((querySnapshot) => {
      const array = []
      const promises = []
      querySnapshot.forEach((doc) => {
        promises.push(
          doc.data().topImage[0].get()
          .then((img) => {
            const imagePath = '/flamelink/media/' + img.data().file
            storage.ref(imagePath).getDownloadURL().then((url) => {
              array.push({...doc.data(), topImage: url})
              if(array.length === querySnapshot.size){
                dispatch(loadProjectsSuccess(array))
              }
            })
          })
        )
      })

    })

  }
}
