import React from 'react'
import {Router} from '@reach/router'

import Header from '../container/Header'
import Footer from '../container/Footer'
import BottomNavigation from '../container/BottomNavigation'
import Home from '../pages/Home'
import Contact from '../pages/Contact'
import Projects from '../pages/Projects'
import ProjectDetails from '../pages/ProjectDetails'
import NotFound from '../pages/NotFound'

const AppRouter = () => {
  return (
    <div className="app-container">
      <Header/>
      <Router>
        <Home path='/'/>
        <Contact path='/contact'/>
        <Projects path='/projects'/>
        <ProjectDetails path='/projects/:id'/>
        <NotFound default/>
      </Router>
      <BottomNavigation/>
      <Footer/>
    </div>
  )
}

export default AppRouter
