import React from 'react'
import {Link} from '@reach/router'
import {useTranslation} from 'react-i18next'

const ProjectItem = ({item}) => {
  const [ t ] = useTranslation('translation');
  const shortLength = 100
  return (
    <Link className="project-item" to={`/projects/${item.id}`}>
      <div className="project-item__img-container">
        <img
          src={item.topImage}
          className="project-item__img"
          alt={`${item.headline}.png`}/>
      </div>
      <div className="project-item__text-container">
        <h3>{item.headline}</h3>
          <p className="project-item__short">
            {item.short.length < shortLength ? item.short : item.short.slice(0,shortLength) + '...'}
          </p>
      </div>
      <div className="bottom">
        <p className="project-item__link">{t('learn_more')}</p>
      </div>

    </Link>
  )
}

export default ProjectItem
