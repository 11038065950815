import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import Grid from '@material-ui/core/Grid';

import ProjectItem from '../components/ProjectItem'
import FilterButton from '../components/FilterButton'

import {loadProjects} from '../redux/actions/projectsActions'
import {setFilterType} from '../redux/actions/filterActions'
import {getFilteredProjects} from '../redux/selectors/projectsSelector'

const Projects = ({loadProjects, projects, filter, setFilterType}) => {
  const [ t ] = useTranslation('translation');

  useEffect(() => {
    document.title=`${t('projects')} - Kevin Haustein`
  }, [t])

  useEffect(() => {
    loadProjects()
  }, [loadProjects])

  // const testProjects = [
  //   {id: 1,img: 'https://via.placeholder.com/150', headline: 'Test', short: 'this is a short descriptionthis is a short descriptionthis is a short descriptionthis is a short descriptionthis is a short description'},
  //   {id: 2,img: 'https://via.placeholder.com/150', headline: 'Test', short: 'this is a short description'},
  //   {id: 3,img: 'https://via.placeholder.com/150', headline: 'Test', short: 'this is a short description'},
  //   {id: 4,img: 'https://via.placeholder.com/150', headline: 'Test', short: 'this is a short description'},
  //   {id: 5,img: 'https://via.placeholder.com/150', headline: 'Test', short: 'this is a short description'},
  //   {id: 6,img: 'https://via.placeholder.com/150', headline: 'Test', short: 'this is a short description'},
  //   {id: 7,img: 'https://via.placeholder.com/150', headline: 'Test', short: 'this is a short description'},
  //   {id: 8,img: 'https://via.placeholder.com/150', headline: 'Test', short: 'this is a short description'},
  //   {id: 9,img: 'https://via.placeholder.com/150', headline: 'Test', short: 'this is a short description'},
  //   {id: 10,img: 'https://via.placeholder.com/150', headline: 'Test', short: 'this is a short description'},
  // ]

  return (
    <div>
      <div className="projects__header">
        <div className="projects__filter">
          <FilterButton type='' filter={filter} text={t('all')} onClick={() => setFilterType('')}/>
          <FilterButton type='react' filter={filter} text='React' onClick={() => setFilterType('react')}/>
          <FilterButton type='react_native' filter={filter} text='React Native' onClick={() => setFilterType('react_native')}/>
          <FilterButton type='android' filter={filter} text='Android' onClick={() => setFilterType('android')}/>
        </div>
      </div>
      <div className="content-container">
          <Grid container className="grid-align">
            {projects.map((project) => (
              <Grid item xs={12} sm={6} md={4}  lg={3} key={project.id} className="grid-align">
                <ProjectItem  item={project}/>
              </Grid>
            ))}
          </Grid>
      </div>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    projects: getFilteredProjects(state.projects, state.filters.type),
    filter: state.filters.type
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadProjects: () => dispatch(loadProjects()),
  setFilterType: (type) => dispatch(setFilterType(type))
})

export default connect(mapStateToProps, mapDispatchToProps)(Projects)
