const getFilteredProjects = (projects, filter) => {
  return projects.filter((project) => {
    const matchAll = filter === ''
    const matchType = project.type === filter

    return matchAll || matchType
  })
}

export {getFilteredProjects}
