import React from 'react'
import {Link} from '@reach/router'

const LinkButton = ({to, text, onClick}) => {
  return(
    <Link to={to} className="linkbutton__link" onClick={onClick}>
      <div className="linkbutton">
        {text}
      </div>
    </Link>
  )
}

export default LinkButton
