import React, {useEffect} from 'react'

import {Link} from '@reach/router'


const NotFound = () => {
  useEffect(() => {
    document.title=`404 - Kevin Haustein`
  }, [])
  return (
  <div className="not-found">
    <p className="not-found__404">404</p>
    <p className="not-found__message">
      Please click <Link to="/">here</Link> to go back
    </p>
  </div>
  )
}

export default NotFound
