import React from 'react'
import {BsCardText,BsChat, BsHouse} from 'react-icons/bs'

import BottomNavItem from '../components/BottomNavItem'

const BottomNavigation = () => {
  return (
    <div className="bottom-navigation show-for-mobile">
      <BottomNavItem to='/projects' text='Projects' icon={<BsCardText size={30}/>}/>
      <BottomNavItem to='/' text='Home' icon={<BsHouse size={30}/>}/>
      <BottomNavItem to='/contact' text='Contact' icon={<BsChat size={30}/>}/>
    </div>
  )
}

export default BottomNavigation
