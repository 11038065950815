import React from 'react'

const Headline = ({children}) => {
  return (
    <div className="headline">
      <div>
        <h1 className="headline__text">{children}</h1>
        <div className="headline__underline"></div>
      </div>
    </div>
  )
}

export default Headline
