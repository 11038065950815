import React from 'react'
import {Link} from '@reach/router'
import {useTranslation} from 'react-i18next'

import NavLink from '../components/NavLink'

const Header = () => {
  const [ t ] = useTranslation('translation');
  return (
    <header className="header">
      <div className="content-container header-container">
        <NavLink to='/projects' text={t('projects')}/>
        <Link to="/" className="header-title">
          <div>KH</div>
        </Link>
        <NavLink to='/contact' text={t('contact')}/>
      </div>
    </header>
  )
}

export default Header
