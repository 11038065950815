import React, {useState, useEffect} from 'react'
import { storage} from '../helper/firebase'


const ProjectContent = ({item}) => {
  const [image, setImage] = useState('')
  const getImage = () => {
    item.image[0].get()
    .then((img) => {
      const imagePath = '/flamelink/media/' + img.data().file
      storage.ref(imagePath).getDownloadURL().then((url) => {
        setImage(url)
      })
    })
  }
useEffect(() => {
  if(item.image.length > 0){
    getImage()
  }

}, [getImage, item.image.length])



  return (
    <div className={`project-content__container ${item.imageOrientation === 'right' ? 'project-content--reverse' : ''}`}>
    {
      item.image.length > 0 && <img className="project-content__image" src={image} alt='stuff'/>
      }


      <div className="project-content__text" dangerouslySetInnerHTML={{__html: item.text}}/>
    </div>
  )
}

export default ProjectContent
// dangerouslySetInnerHTML={{__html: project.otherContent}}
