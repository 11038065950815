import firebase from 'firebase/app';
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'


const firebaseConfig = {
  apiKey: "AIzaSyBWoJBZ4aRxhZvMyjNpnB7tvk4f0T-m_Lo",
  authDomain: "portfolio-b63f9.firebaseapp.com",
  databaseURL: "https://portfolio-b63f9.firebaseio.com",
  projectId: "portfolio-b63f9",
  storageBucket: "portfolio-b63f9.appspot.com",
  messagingSenderId: "1036860798033",
  appId: "1:1036860798033:web:f3b0626a04c0b0bfcdb470",
  measurementId: "G-3G605JVBSN"
};

firebase.initializeApp(firebaseConfig)
const db = firebase.firestore()
const storage = firebase.storage()
const functions = firebase.functions()

export {db, storage, functions}
