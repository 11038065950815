import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {Link} from '@reach/router'

import ProjectContent from '../container/ProjectContent'
import {loadProjects} from '../redux/actions/projectsActions'

const ProjectDetails = ({project, history, loadProjects}) => {
  const [ t ] = useTranslation('translation');

  useEffect(() => {
    document.title=`${project.headline} - Kevin Haustein`
  }, [project])

  useEffect(() => {
    loadProjects()
  }, [loadProjects])

  return (
    <div className="content-container">
      {!project && <div className="not-found">
        <p className="not-found__404">404</p>
        <p className="not-found__message">
          Please click <Link to="/">here</Link> to go back
        </p>
      </div>}
      {project &&
        <div className="project-details">
          <button onClick={() => window.history.back()}>{t('back_to_projects')}</button>

          <img src={project.topImage} alt='' className="project-details__top-image"/>
          <h1 className="project-details__headline">{project.headline}</h1>
          {
            project.content.map((item) => (
              <ProjectContent key={item.uniqueKey} item={item}/>
            ))
          }
          <div className="project-details__link-container">
            <h2>Links:</h2>
            {project.links.map((link) => {
              return (<a className="project-details__link" href={link.link} key={link.uniqueKey}>{link.link}</a>)
            })
            }
          </div>
        </div>
      }
    </div>

  )
}

const mapStateToProps = (state, props) => {
  return {
    project: state.projects.find((project) => project.id === props.id),
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadProjects: () => dispatch(loadProjects()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails)
