import React from 'react'

const SocialLink = ({href, text, img}) => {
  return (
    <a href={href} className="social-link">
      <img className="social-link__img" src={img} alt={text}/>
      {text}
    </a>
  )
}

export default SocialLink
