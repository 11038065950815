import React, {useState, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'

import LinkButton from '../components/LinkButton'
import Headline from '../components/Headline'
import {setFilterType} from '../redux/actions/filterActions'
import {db, storage} from '../helper/firebase'

const Home = ({setFilterType}) => {
  const [description, setDescription] = useState('')
  const [picture, setPicture] = useState('')

  const hero = useRef(null)

  useEffect(() => {
    document.title=`Kevin Haustein`
  }, [])

  useEffect(() => {
    db.collection("fl_content").where("_fl_meta_.schema", "==",  "aboutSection")
    .get()
    .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            setDescription(doc.data().description)

            doc.data().avatar[0].get()
            .then((img) => {
              const imagePath = '/flamelink/media/' + img.data().file
              storage.ref(imagePath).getDownloadURL().then((url) => {
                setPicture(url)
              })
            })
        });
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });
  }, [])

  const [ t ] = useTranslation('translation');
  return (
    <div>
      <div ref={hero} className="hero-section--background">
        <div className="hero-section__content">
          <h1>Webdesign.</h1>
          <h1>Mobile Apps.</h1>
          <p>Softwarelösungen unabhängig von den Endgeräten Deiner User.</p>
          <span className="hero-section__cta"><LinkButton to="/projects" text={t('portfolio')}/></span>
        </div>
      </div>

      <div className="button-section">
        <Headline>{t('projects')}</Headline>
          <div className="button-section__content">
            <LinkButton to="/projects" onClick={() => setFilterType('react')} text="React"/>
            <LinkButton to="/projects" onClick={() => setFilterType('react_native')} text="React Native"/>
            <LinkButton to="/projects" onClick={() => setFilterType('android')} text="Android"/>
        </div>
      </div>
      <div className="about-section">
        <Headline>{t('about')}</Headline>
        <img className="about-section__avatar" src={picture} alt="Me"/>
        <p className="about-section__description">{description}</p>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setFilterType: (filter) => dispatch(setFilterType(filter))
})

export default connect (undefined, mapDispatchToProps)(Home)
