import React from 'react'
import {Link} from '@reach/router'

const NavLink = ({text, to}) => {
  return (
      <Link className="navlink show-for-desktop" to={to}>
        {text}
      </Link>
  )
}

export default NavLink
