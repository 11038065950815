import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import ContactForm from '../container/ContactForm'
import Headline from '../components/Headline'

const Contact = () => {
  const [ t ] = useTranslation('translation');

  useEffect(() => {
    document.title=`${t('contact')} - Kevin Haustein`
  }, [t])

  return (
    <div className="contact-section">
      <Headline>{t('contact_me')}</Headline>
      <ContactForm/>
    </div>
  )
}

export default Contact
