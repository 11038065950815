import React from 'react'

import {Link} from '@reach/router'

const BottomNavItem = ({icon, text, to}) => {
  return (
    <Link className="bottom-nav-item" to={to}>
      {icon}
      {text}
    </Link>
  )
}

export default BottomNavItem
