import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css/normalize.css';
import './styles/index.scss';
import './app/helper/i18n'
import './app/helper/firebase'
import AppRouter from './app/router/AppRouter';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'

import storeconfig from './app/redux/store'

const store = storeconfig()

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="loading">
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
