import React from 'react'

import SocialLink from '../components/SocialLink'

const Footer = () => {
  return (
    <div className="bottom">
      <footer className="footer">
        <div className="footer--center">
          <SocialLink href="https://github.com/WyWayt" text="Github" img="/images/github.svg.png"/>
          <SocialLink href="https://www.xing.com/profile/Kevin_Haustein3/cv" text="Xing" img="/images/xing.png"/>
        </div>
        <div className="footer--center">
          <p>Kevin Haustein ©2020</p>
        </div>
      </footer>
    </div>
  )
}

export default Footer
