const filtersReducerDefaultState = {
  type: '',
}

const filtersReducer = (state = filtersReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_FILTER_TYPE':
      return {
        type: action.filter
      }
      default:
        return state
    }
  }

export default filtersReducer
